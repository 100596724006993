@tailwind base;
@tailwind components;
@tailwind utilities;

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 1em;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes opacityIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.7;
  }
}

@keyframes scaleIn {
  0% {
    scale: 0;
  }

  100% {
    scale: 1;
  }
}

@keyframes toGray {
  0% {
    background-color: #fff;
  }

  100% {
    background-color: #f3f4f6;
  }
}

/* Transitions */

.bgToWhite {
  background-color: #fff;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.bgToLightGray {
  background-color: #f3f4f6;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.opacityIn {
  opacity: 100;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.opacityOut {
  opacity: 0;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.scaleOut {
  scale: 1;
  transition: all 1150ms cubic-bezier(0.27, 1.64, 0.41, 0.8);
}

.scaleIn {
  scale: 1.3;
  transition: all 1150ms cubic-bezier(0.27, 1.64, 0.41, 0.8);
}

/* Table */

tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 0.5rem;
}

tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 0.5rem;
}

table {
  @apply border-spacing-x-0 border-spacing-y-1.5;
}

tr {
  padding-left: 10px;
  margin-left: 10px;
}

td {
  border-left: none;
  border-right: none;
  @apply border border-gray-200;
}

td:first-child {
  border-left: solid 1px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  @apply border border-gray-200;
}

td:last-child {
  border-right: solid 1px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  @apply border border-gray-200;
}

/* Input */

textarea:focus,
input:focus {
  outline: none;
}

/* Scroll bar */

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(217, 217, 217);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(201, 201, 201);
}

input[type="password"]::-webkit-credentials-auto-fill-button {
  transform: translateX(-20px);
}
